<template>
  <div>
    <Header role="user" />
    <div class="d-flex" id="wrapper">
      <Sidebar role="user" />
      <div class="signupBgColour" id="page-content-wrapper">
        <div class="row">
          <div class="col-lg-11 col-12 userOutline">
            <div class="text w-100">
              <!-- <div class="d-block d-lg-none m-2">
                <div class="userMobileindexHeader">

                  <div class="searchInputBox">
                    <input class="form-control" type="search" name="search" placeholder="Search">
                  </div>
                </div>
              </div> -->
              <router-view />                                           
            </div>
          </div>
          <div class="col-lg-4 d-lg-block d-none">
            <MessageList />     
          </div>
        </div>


      </div>
    </div>
    <div class="d-block d-lg-none"
      v-if="!['Doctor-New-Message', 'Doctor-Message-Chat', 'Doctor-Comment'].includes($route.name)">
      <Footer role="user"></Footer>
    </div>
  </div>
</template>

<script>
import Sidebar from '../layouts/Sidebar.vue'
import Header from '../layouts/Header.vue'
import Footer from '../layouts/CommonFooter.vue'
import MessageList from '../../views/common/messagelist.vue'
import '../../css/app.css'
export default {

  name: 'app',
  components: {
    Sidebar,
    Header,
    Footer,
    MessageList,
  },
  data() {
return {
  publicUserID:'',
}},

created: async function () {
  await this.getPublic();
},
methods: {

  async getPublic() {
    this.publicUserID = localStorage.getItem('publicSignUpData')
if(!this.publicUserID){
    window.location.href = "/";
  }
}
}
}
</script>
<style>

.headerIcon {
  width: 50px;
  padding-left: 5px;
  padding-right: 5px;
}

.userMobileindexHeader {
  display: flex;
  justify-content: space-between;
  border: 1px solid #000;
  padding: 5px;
  align-items: center;
}
.text.w-100 {
  margin-bottom: 60px;
}

#page-content-wrapper {
  background-color: #f3f2ef;
      /* THIS OVERFLOW:HIDDEN IS JUST TEMPORARY FIX AND THIS IS FIXED FOR SCREEN SHAKING */
  overflow: hidden;
}

@media screen and (max-width:991px) {
  .common-mobile-icon{
  font-size: 30px;
  color: #000;
  padding-left: 5px;
  padding-right: 5px;
}
  #page-content-wrapper {
    background-color: #fff;
    overflow: hidden;
  }
}

</style>